<template>
    <div>
        <main class="form-signin">
            <div class="card">
                <div class="card-body">
                    <form @submit="onSubmit">
                        <h2 class="h3 mb-3 fw-normal text-center">速可貸</h2>

                        <div class="form-group">
                            <label>帳號</label>
                            <input type="account" required v-model="form.account" class="form-control form-control-lg" autofocus
                                   placeholder="請輸入帳號"/>
                        </div>

                        <div class="form-group">
                            <label>密碼</label>
                            <input type="password" required v-model="form.password" class="form-control form-control-lg"
                                   placeholder="請輸入密碼"/>
                        </div>

                        <b-button class="w-100 btn btn-lg btn-primary" type="submit" variant="primary">登入</b-button>
                    </form>
                </div>
            </div>
            <p class="mt-3 mb-3 text-muted text-center">© 2020–2021</p>
        </main>
        <b-modal ok-only v-model="modalShow">{{ modelMsg }}</b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                account: '',
                password: '',
            },
            modalShow: false,
            modelMsg: '',
            loginFailureBlocking: false,
        }
    },
    methods: {
        async onSubmit() {
            const self = this;
            if (self.loginFailureBlocking) {
                // 防止 client 網頁掛腳本直接把按鈕的 disabled 拉掉
                return false;
            }

            self.disableSubmitBtn();
            let response = await this.axios.post(self.GLOBAL.serverSrc + "login.php", {
                username: self.form.account,
                password: self.form.password
            })

            if (response.data.status === '1') {
                sessionStorage.setItem('account', self.form.account);
                sessionStorage.setItem('token', response.data.token);

                self.modelMsg = '登入成功，頁面將在兩秒後重新整理畫面';
                setTimeout(function () {
                    self.$router.push({name: 'Home'});
                    location.reload();
                }, 2000);
            } else {
                self.form.account = '';
                self.form.password = '';
                self.modelMsg = '帳號密碼錯誤，請重新輸入!!!';
                self.loginFailureBlocking = true;

                setTimeout(() => {
                    self.disableSubmitBtn(false);
                    self.loginFailureBlocking = false;
                }, 5000);
            }

            self.modalShow = true;
        },

        disableSubmitBtn(disabled = true) {
            let btn = this.$el.querySelector('button[type="submit"]');
            btn.disabled = disabled;
        }
    }
}
</script>

<style scoped>
.form-signin {
    width: 100%;
    max-width: 450px;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 125px;
}

label {
    font-weight: 600;
}
</style>
